export default [
  {
    path: "/profile",
    name: "profile",

    component: () => import("@/views/admin/profilePage.vue"),
    meta: {
      auth_roles: [1,2, 3, 4, 6, 7, 9,8,],
      authRequired: true,
      title: "profile",
    },
  },
  {
    path: "/users",
    name: "users",

    component: () => import("@/views/admin/usersPage.vue"),
    meta: {
      auth_roles: [1, 9],
      authRequired: true,
      title: "users",
    },
  },
  {
    path: "/users/add-user",
    name: "addUser",

    component: () => import("@/views/admin/add-user.vue"),
    meta: {
      auth_roles: [1, 9],
      authRequired: true,
      title: "add_user",
    },
  },
  {
    path: "/users/show-user/:id",
    name: "showUser",

    component: () => import("@/views/admin/show-user.vue"),
    meta: {
      auth_roles: [1, 9],
      authRequired: true,
      title: "show_user",
    },
  },
  {
    path: "/users/edit-user/:id",
    name: "editUser",

    component: () => import("@/views/admin/edit-user.vue"),
    meta: {
      auth_roles: [1, 9],
      authRequired: true,
      title: "edit_user",
    },
  },
  {
    path: "/users-activities",
    name: "activities",

    component: () => import("@/views/admin/users-activities.vue"),
    meta: {
      auth_roles: [1, 9],
      authRequired: true,
      title: "activities",
    },
  }
];
