export default [
  {
    path: "/create",
    name: "create",

    component: () => import("../../views/users/create-accounts.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "create",
    },
  },
  {
    path: "/create-new-account",
    name: "createNewAccount",

    component: () => import("../../views/users/create-new-account"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "create",
    },
  },
  {
    path: "/update-existing-account",
    name: "updateExistingAccount",

    component: () => import("../../views/users/update-existing-account.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "create",
    },
  },
  {
    path: "/customer-overview",
    name: "customerOverview",

    component: () => import("../../views/users/customer-overview.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "customer-overview",
    },
  },
  {
    path: "/existing-customer-data-overview",
    name: "existingCustomerDataOverview",

    component: () => import("../../views/users/existing-customer-data-overview.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "existing-customer-data-overview",
    },
  },
  {
    path: "/all-users",
    name: "allUsers",

    component: () => import("../../views/users/all-accounts.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "all-users",
    },
  },
  {
    path: "/all-users/account-details/:id",
    name: "accountDetails",
    sensitive:true,

    component: () => import("@/views/users/account-details.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "account-details",
    },
  },
  {
    path: "/all-users/account-details/login-history/:id",
    name: "loginHistory",

    component: () => import("../../views/users/login-history.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "login-history",
    },
  },
  {
    path: "/all-users/account-details/user-transactions/:id",
    name: "userTransactions",

    component: () => import("@/views/users/view-user-transactions.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "user-transactions",
    },
  },
  {
    path: "/active-accounts",
    name: "active",

    component: () => import("@/views/users/active-accounts.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "active",
    },
  },
  {
    path: "/inactive-accounts",
    name: "inactive",

    component: () => import("@/views/users/inactive-accounts.vue"),
    meta: {
      auth_roles: [1, 3],
      authRequired: true,
      title: "inactive",
    },
  },
];
