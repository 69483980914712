<template>
          
  <router-view/>
</template>

<style lang="scss">


.dt-search {
  margin-bottom: 10px; /* Adjust the margin bottom as needed */
  display: inline-flex;
  align-items: center;
}
.dt-search label{
  margin-right: 5px;
  margin-bottom: 0;
}
.dt-length {
  margin-bottom: 10px; /* Adjust the margin bottom as needed */
  display: inline-flex;
  align-items: center;
}
.dt-length select{
  margin-right: 5px;
  width: 50px;
}
.dt-length label{
  margin-right: 5px;
  margin-bottom: 0;
}
.dataTables_wrapper .dataTables_ordering {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.table-custom {
    --bs-table-color: #666666 !important;
    --bs-table-bg: #e8e8e8 !important;
    --bs-table-border-color: #c6d5e3 !important;
    --bs-table-striped-bg: black !important;
    --bs-table-striped-color: #fff !important;
    --bs-table-active-bg: black !important;
    --bs-table-active-color: #fff !important;
    --bs-table-hover-bg: black !important;
    --bs-table-hover-color: #fff !important;
    color: var(--bs-table-color) !important;
    border-color: var(--bs-table-border-color) !important;
}
</style>

<script>
// eslint-disable-next-line
export default {
     watch: {
    "$i18n.locale": function (locale) {
      localStorage.current_language = locale;
      if (localStorage.current_language == "ar") {
        document.querySelector("html").setAttribute("dir", "rtl");
        document.querySelector("html").setAttribute("lang", "ar");
        // document.body.classList.add("ar")
      } else {
        document.querySelector("html").setAttribute("dir", "ltr");
        document.querySelector("html").setAttribute("lang", "en");
        // document.body.classList.remove("ar")
      }
    },
  },
   methods: {
    logout(){
         localStorage.removeItem("currentUser")
      this.$router.push("/login");
    }
  },
  mounted() {



  let timeout;
  let resetTimer = ()=> {
    console.log("step 94 :");
    
      clearTimeout(timeout);
      timeout = setTimeout(this.logout, 300000);
  }
  
  resetTimer();
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;




  },

}
</script>