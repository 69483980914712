import store from "../state/store.js";
import axios from "axios";
import Swal from "sweetalert2";
import router from "../router";
// axios.defaults.withCredentials = true
// axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] ='*';
// axios.defaults.headers['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers['crossorigin'] = true;

// resp.headers("Access-Control-Allow-Origin", "*");
  console.log("step 12 :", store );
  
export default {
  install(Vue) {
    console.log("step 17 :", Vue.config.globalProperties.$store);
    
    var self = Vue.config.globalProperties;
    Vue.config.globalProperties.http = {
      post(url, data, options,loading) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (self.$store?.state?.token) {
            options.headers["token"] = self.$store?.state?.token;

            data.user_id = self.$store?.state?.currentUser.id;
          }
          console.log(options);
          if(!options.headers['route']){
            options.headers['route']= router.currentRoute.value.path
          }
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            if (!data.user_id) {
              data.user_id = current_user?.id;
            }
            if(!options.headers['user-id']){
              options.headers['user-id']=current_user?.id
            }
            
          }
          data.lang = Vue.config.globalProperties.$i18n.locale;
          if(loading) Vue.config.globalProperties.$loading.show()
            axios.post(self.$store?.state?.baseUrl + url, data, options).then((resp) => {
            // resp.headers["Access-Control-Allow-Origin"] = "*";
            console.log(resp.headers["token"]);
            if (resp.headers["token"]) {
              self.store.state.token = resp.headers["token"];
              console.log(data.pass);
              resp.data.data.pass = self.$e.encrypt(data.pass);
              self.$store.state.currentUser = resp.data.data;
              localStorage.currentUser = JSON.stringify(resp.data.data);
              console.log(self.$store?.state?.token);
            }
             Vue.config.globalProperties.$loading.hide()
            if (resp.data.status) {
              resolve(resp.data);
            } else {
              console.log('e', resp?.data?.data?.massage);
              // self.popup.alert({
              //   title: "popups.error",
              //   msg: resp.data.data.message
              // });
              resolve(resp.data);
            //   Swal.fire({
            //     title: resp.data.data.message,
            //     icon: "error",
            //     timer: 1500,
            // });
            }
          }).catch((e)=> {
             
            Swal.fire({
              title: Vue.config.globalProperties.$t('messages.network_is_unstable'),
              icon: "error",
          });

            Vue.config.globalProperties.$loading.hide()
            console.log(e)
          
          }
          );
        });
      },
      do(url, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (self.$store?.state?.token) {
            options.headers["token"] = self.$store?.state?.token;
            data.user_id = self.$store?.state?.currentUser.id;
          }
          if(!options.headers['route']){
            options.headers['route']= router.currentRoute.value.path
          }
          console.log(options);
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            if (!data.user_id) {
              data.user_id = current_user?.id;

            }
            if(!options.headers['user-id']){
              options.headers['user-id']=current_user?.id
            }
          }
          data.lang = Vue.config.globalProperties.$i18n.locale;
          var formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          axios.post(self.$store?.state?.baseUrl + url, formData, options).then((resp) => {
            console.log(resp.headers["token"]);
            if (resp.headers["token"]) {
              self.$store.state.token = resp.headers["token"];
              console.log(data.pass);
              resp.data.data.pass = self.$e.encrypt(data.pass);
              self.$store.state.currentUser = resp.data.data;
              localStorage.currentUser = JSON.stringify(resp.data.data);
              console.log(self.$store?.state?.token);
            }
            if (resp.data.status) {
              resolve(resp.data);
            } else {
              resolve(resp.data);
            //   Swal.fire({
            //     title: resp?.data?.data?.message,
            //     icon: "error",
            //     timer: 1500,
            // });
            }
          }).catch((e)=> {
            console.log(e)
            Swal.fire({
              title: Vue.config.globalProperties.$t('messages.network_is_unstable'),
              icon: "error",
          });
          });
        });
      },
      put(url, data, options,loading) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (self.$store?.state?.token) {
            options.headers["token"] = self.$store?.state?.token;
            data.user_id = self.$store?.state?.currentUser.id;
          }
          
          if(!options.headers['route']){
            options.headers['route']= router.currentRoute.value.path
          }
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            if (!data.user_id) {

              data.user_id = current_user?.id;

            }

            if(!options.headers['user-id']) {
              options.headers['user-id'] = current_user?.id
            }
          }
          // const currentUser = JSON.parse(localStorage.currentUser);
          // data.user_id = currentUser.id;
          console.log(options);
          // data.lang = Vue.config.globalProperties.$i18n.locale
          if(loading) Vue.config.globalProperties.$loading.show()

          axios
            .put(self.$store?.state?.baseUrl + url,data, options)
            .then((resp) => {
              console.log(resp.headers["token"]);
              if (resp.headers["token"]) {
                self.$store.state.token = resp.headers["token"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                self.$store.state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(self.$store?.state?.token);
              }
              Vue.config.globalProperties.$loading.hide()
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                resolve(resp.data);
                // self.popup.alert({
                //   title: "popups.error",
                //   msg: resp.data.msg
                // });
              }
            }).catch((e)=> {
              Vue.config.globalProperties.$loading.hide()
              console.log(e)
              Swal.fire({
                title: Vue.config.globalProperties.$t('messages.network_is_unstable'),
                icon: "error",
            });
            });
        });
      },
      get(url,data, options,loading) {
        
        return new Promise((resolve) => {
          if (!data)  data = {};
          
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (self.$store?.state?.token) {
            options.headers["token"] = self.$store?.state?.token;
            data.user_id = self.$store?.state?.currentUser.id;
          }
          
          if(!options.headers['route']){
            options.headers['route']= router.currentRoute.value.path
          }
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            data.user_id = current_user.id;

            if(!options.headers['user-id']){
              options.headers['user-id']= current_user?.id

              console.log("step 183 :",options.headers);
              
            }
          }
          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          if(loading) Vue.config.globalProperties.$loading.show()
          axios
            .get(
              self.$store?.state?.baseUrl + url + "?" + new URLSearchParams(data).toString(),
              options
            )
            .then((resp) => {
              // console.log("the status is",resp.data.status)
              console.log(resp.headers["token"]);
              if (resp.headers["token"]) {
                self.$store.state.token = resp.headers["token"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                self.$store.state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(self.$store?.state?.token);
              }
              Vue.config.globalProperties.$loading.hide()
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                resolve(resp.data);
                Swal.fire({
                  title: resp.data.data.message,
                  icon: "error",
              });
              }
            }).catch((e)=> {
              Vue.config.globalProperties.$loading.hide()
              console.log(e)
              Swal.fire({
                title: Vue.config.globalProperties.$t('messages.network_is_unstable'),
                icon: "error",
            });
            });
        });
      },
      delete(url, id, options) {
        return new Promise((resolve) => {
          var data = {};
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (self.$store?.state?.token) {
            options.headers["token"] = self.$store?.state?.token;
            data.user_id = self.$store?.state?.currentUser.id;
          }
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            if(!options.headers['user-id']){
              options.headers['user-id']=current_user?.id
            }
          }
          if(!options.headers['route']){
            options.headers['route']= router.currentRoute.value.path
          }
          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          axios
            .delete(
              self.$store?.state?.baseUrl +
                url +
                "/" +
                id +
                "?" +
                new URLSearchParams(data).toString(),
              options
            )
            .then((resp) => {
              console.log(resp.headers["token"]);
              if (resp.headers["token"]) {
                self.$store.state.token = resp.headers["token"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                self.$store.state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(self.$store?.state?.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
              } else {
               resolve(resp.data)
              }
            });
        });
      },
      download(url, fileName,data, options) {
        return new Promise((resolve) => {
          if(!data)
            data = {};
          if (!options) options = { timeout: 5 * 60 * 1000 };
          if (!options.headers) options.headers = {};
          if (self.$store?.state?.token) {
            options.headers["x-auth"] = self.$store?.state?.token;
            data.user_id = self.$store?.state?.currentUser.id;
          }
          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;

          // progress bar
          function isIE() {
            var myNav = navigator.userAgent.toLowerCase();
            return myNav.indexOf("msie") != -1
                ? parseInt(myNav.split("msie")[1])
                : false;
          }

          axios({
            method: "GET",
            url:
                self.$store?.state?.baseUrl + url + "?" + new URLSearchParams(data).toString(),
            data,
            responseType: "arraybuffer",

          })
              .then((data) => {
                var D = document;
                var ieVersion = isIE();
                if (ieVersion && ieVersion < 10) {
                  var frame = D.createElement("iframe");
                  document.body.appendChild(frame);

                  frame.contentWindow.document.open("text/html", "replace");
                  frame.contentWindow.document.write("sep=,\r\n" + data.data);
                  frame.contentWindow.document.close();
                  frame.contentWindow.focus();
                  console.log("hi 1");
                  if (
                      frame.contentWindow.document.execCommand(
                          "SaveAs",
                          true,
                          fileName
                      )
                  ) {
                    console.log("Bill is saved");
                  } else {
                    Vue.config.globalProperties.popup.alert({
                      title:"popups.error",
                      msg:"File cannot be saved. Your browser does not support download."
                    });

                  }

                  document.body.removeChild(frame);
                  return true;
                }

                var file = new Blob([data.data]);
                if (typeof window.navigator.msSaveBlob !== "undefined") {
                  window.navigator.msSaveBlob(file, fileName);
                  console.log("hi msSaveBlob");
                } else {
                  //for chrome in IOS
                  if (navigator.userAgent.match("CriOS")) {
                    file = new Blob([data]);
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  } else {
                    fileURL = URL.createObjectURL(file);
                    var a = document.createElement("a");
                    if (typeof a.download === "undefined") {
                      window.location = fileURL;
                      console.log("hi win 1");
                    } else {
                      a.href = fileURL;
                      a.target = "_blank";
                      a.download = fileName;
                      document.body.appendChild(a);
                      a.click();
                      console.log("hi win 2");
                    }
                  }
                }
                resolve(true);
              })
              .catch((e) => {
                // $.LoadingOverlay("hide");
                console.log(e);
                var reader = new FileReader();
                reader.onload = function (event) {
                  console.log(event.target.result);
                  var st = JSON.parse(atob(event.target.result.substring(37)));
                  Vue.config.globalProperties.popup.alert({
                    title:"popups.error",
                    msg:st.msg
                  });
                  //resolve(false);
                };
                var array = new Uint8Array(e.data);
                var blob = new Blob([array]);
                reader.readAsDataURL(blob);
              });
        });
      }
    };
  },
};
