import { createRouter, createWebHistory } from 'vue-router'
import users from "./routes/users.js"
import favorites from "./routes/favorites.js"
import general from "./routes/general.js";
import intro from "./routes/intro.js";
import faq from "./routes/faq.js";
// import services from "./routes/services.js"
import notifications from "./routes/notifications.js"
import admin from "./routes/admin.js"
import custom from "./routes/custom.js"
import transactions from './routes/transactions.js';
const routes = [
 
  
  {
path:'/',
redirect:'/home'
  },
  {
    path: "/",
    name: "app",
    component: () => import("../views/LayoutPage.vue"),
    children:[
    {
     path:"home",
     name: "home",
     component: () => import("../views/dashboard/dashboardPage.vue"),
     meta: {
      auth_roles: [1,2],
      authRequired: true,
      title: "Home",
    },
      },
     ...users,
     ...admin,
     ...favorites,
     ...general,
     ...intro,
     ...faq,
// ...services,
...notifications,
...custom,
...transactions


    ]

  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/loginPage.vue"),
    meta: {
      title: "Login",

    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  mode: "history",

  scrollBehavior(to, from, savedPosition) {
    console.log("step 70 :",savedPosition);
    
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };

  }
})


router.beforeEach((routeTo, routeFrom, next) => {

  const publicPages = ["/login"];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = localStorage.getItem("currentUser");

  if (!authpage)
    console.log("login page");
  else if (authpage && !loggeduser) {
    return next("/login");
  }
  else {
    const decoded_user_data = JSON.parse(loggeduser);
    const user_roles = decoded_user_data?.permissions.map((item) => item.permission.id);
    console.log('ro', user_roles);
    const page_roles = routeTo.meta.auth_roles;
    console.log('pro', page_roles);
    let auth_status = false;
    for (let i = 0; i < user_roles?.length; i++) {
   
      if (page_roles?.includes(user_roles[i])) {
        auth_status = true;
        break;
      } else {
        auth_status = false;
      }
    }
    if (auth_status) {
      console.log("auth complete");
    } else {
      router.push("/profile");
      console.log("not allowed");
      next();
    }
  }
  
  next();

});
export default router
