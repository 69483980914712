export default [
    {
      path: "/services",
      name: "services",
  
      component: () => import("@/views/data/service-status.vue"),
      meta: {
        auth_roles: [1, 7],
        authRequired: true,
        title: "services",
      },
    },
    {
      path: "/service-pricing",
      name: "servicePricing",
  
      component: () => import("@/views/data/service-pricing.vue"),
      meta: {
        auth_roles: [1, 7],
        authRequired: true,
        title: "servicePricing",
      },
    },
    {
        path: "/version-controller",
      name: "versionController",
    
        component: () => import("@/views/data/general/version-controller.vue"),
        meta: {
          auth_roles: [1, 7],
          authRequired: true,
          title: "version-controller",
        },
      }
  ];
  