export default [
    {
        path: "/bank-notifications",
        name: "bank-notifications",

        component: () => import("@/views/notifications/bank-notifications.vue"),
        meta: {
            auth_roles: [1, 8],
            authRequired: true,
            title: "bank-notifications",
        },
    },
    {
        path: "/bank-notifications/send-notifications",
        name: "sendNotifications",

        component: () => import("@/views/notifications/send-bank-notification.vue"),
        meta: {
            auth_roles: [1, 8],
            authRequired: true,
            title: "meters",
        },
    },
    {
        path: "/bank-notifications/show-notifications/:id",
        name: "bankNotificationsDetails",

        component: () => import("@/views/notifications/show-bank-notification.vue"),
        meta: {
            auth_roles: [1, 8],
            authRequired: true,
            title: "numbers",
        },
    },
];
