export default [
  {
    path: "/faq",
    name: "faq",

    component: () => import("@/views/data/general/faq/faqPage.vue"),
    meta: {
      auth_roles: [1, 6],
      authRequired: true,
      title: "faq",
    },
  },
  {
    path: "/faq/add-question",
    name: "addQuestion",

    component: () => import("@/views/data/general/faq/add-question.vue"),
    meta: {
      auth_roles: [1, 6],
      authRequired: true,
      title: "add-question",
    },
  },
  {
    path: "/faq/show-question/:id",
    name: "showQuestion",

    component: () => import("@/views/data/general/faq/show-question.vue"),
    meta: {
      auth_roles: [1, 6],
      authRequired: true,
      title: "show-question",
    },
  },
  {
    path: "/faq/edit-question/:id",
    name: "editQuestion",

    component: () => import("@/views/data/general/faq/edit-question.vue"),
    meta: {
      auth_roles: [1, 6],
      authRequired: true,
      title: "edit-question",
    },
  },
];
