export default [
  {
    path: "/intropage",
    name: "introPage",

    component: () => import("@/views/data/general/intro/introPage.vue"),
    meta: {
      auth_roles: [1, 6],
      authRequired: true,
      title: "intropage",
    },
  },
  {
    path: "/intropage/show-intropage/:id",
    name: "showIntroPage",

    component: () => import("@/views/data/general/intro/show-intropage.vue"),
    meta: {
      auth_roles: [1, 6],
      authRequired: true,
      title: "show-intropage",
    },
  },
  {
    path: "/intropage/edit-intropage/:id",
    name: "editIntropage",

    component: () => import("@/views/data/general/intro/edit-intropage.vue"),
    meta: {
      auth_roles: [1, 6],
      authRequired: true,
      title: "edit-intropage",
    },
  },
];
