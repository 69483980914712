import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from "./i18n.js";

import store from './state/store.js'

import popups from "@/services/popups";
import http from "@/services/http";
import $e from "@/services/$e";
import Login from "@/services/Login";
import setTitle from '@/components/setTitle.vue'
import 'sweetalert2/dist/sweetalert2.min.css';
import loading from './services/loading';
import moment from "@/services/moment";

// import 'bootstrap/dist/css/boo'
// import 'owl.carousel';
// import 'bootstrap/dist/js/bootstrap.bundle.js';



createApp(App)
.use(store)
.use(popups)
.use(i18n)
.use(http)
.use($e)
.use(Login)
.use(router)
.use(loading)
.use(moment)
.component("setTitle", setTitle)
.mount('#app')
